<template>
  <div class="upgrade-trip-checkout-view tw-mb-5">
    <v-container v-if="checkoutLoading" class="pt-0">
      <v-row>
        <v-col>
          <map-icon-loader />
        </v-col>
      </v-row>
    </v-container>
    <v-container class="tw-text-left pt-0" v-if="!checkoutLoading && checkoutSession">
      <v-row>
        <v-col>
          <div id="stripe-checkout"></div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="tw-text-center">
          <p class="tos-disclaimer">By clicking Pay, you accept the Terms &amp; Conditions. If you’re not loving it, contact support@letsjetty.com within 14-days of purchase for a refund.</p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import MapIconLoader from '../components/loaders/MapIconLoader.vue';
export default {
  components: { MapIconLoader },
  name: "UpgradeTripCheckoutView",

  data() {
    return {
      stripe: null,
      checkout: null,
      checkoutLoading: false,
    };
  },

  computed: {
    sessionUser() {
      return this.$store.state.auth.user;
    },
    wallet() {
      return this.sessionUser.user_wallet;
    },
    checkoutSession() {
      return this.$store.state['checkout-session'].keyedById[this.$route.params.checkoutId];
    },
  },

  methods: {
    async mountCheckoutForm() {
      const { client_secret } = this.checkoutSession;
      if (client_secret) {
        this.checkout = await this.stripe.initEmbeddedCheckout({
          clientSecret: client_secret,
          //onComplete: this.goToConfirm(),
        });

        this.checkout.mount("#stripe-checkout")
      }
    },

    goToConfirm() {
      this.$router.push({ name: 'UpgradeTripCheckoutConfirmRoute', params: {
        id: this.$route.params.id,
        checkoutId: this.$route.params.checkoutId,
      }});
    }
  },

  async beforeMount() {
    this.checkoutLoading = true;

    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "My Cart",
      bgColor: null,
      fontColor: "#203848",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      startDate: null,
      showBackButton: true,
      endDate: null
    });

    // Dispatch the action to get trip details
    await this.$store.dispatch("checkout-session/get", this.$route.params.checkoutId);
    this.stripe = await window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
    this.checkoutLoading = false;
    await this.mountCheckoutForm();
  },
  mounted() {
  },
  async beforeDestroy() {
    await this.checkout.unmount();
    await this.checkout.destroy();
  }
};
</script>

<style lang="scss">
.upgrade-trip-checkout-view {
  .tos-disclaimer {
    font-family: Figtree;
    font-size: 11px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: #949494;
  }
}
</style>
